import styled from "styled-components";
import { SectionTitle } from "../components/SectionTitle";
import { TiHeart } from "react-icons/ti";
import { FaTelegram } from "react-icons/fa";
import { SiProtonmail } from "react-icons/si";

export const ContactSection = () => {
  return (
    <>
      <ContactContainer>
        <div id="contact" style={{ color: "transparent" }}>
          Contact
        </div>
        <ContentContainer>
          <SectionTitle title="Contact" />

          <TextContainer>
            <ContactSubTitle>
              {" "}
              Introduce yourself properly. Let me know a bit about yourself and
              makes sure to include:
            </ContactSubTitle>
            <ul>
              <li>Your interests</li>
              <li>Your boundaries / hard limits</li>
              <li>
                Health issues that could affect the dynamic (i.e: post-surgery,
                a 'bad knee', asthma etc){" "}
              </li>
              <li>Dates you'd be available for the session</li>
            </ul>
            <ContactSubTitle>
              Only half of the initial messages I receive are minimally
              intelligible. The others, I don't bother to reply to. Pick your
              half.
            </ContactSubTitle>
            <ContactSubTitle>
              Here is an example of an acceptable message. Feel free to use it
              as a template:
            </ContactSubTitle>
            <TextQuote>
              "Hello Mistress.
              <br /> My name is _____ and I'm based in _______. I'm visiting
              Amsterdam next week from ____ to ____ and staying at the ______
              Hotel.
              <br />
              These are practices I'm into: - <br />
              - <br />
              <br />
              <br />
              My limits are: - <br />
              - <br />
              I have no health conditions but ______ (allergies, asthma,
              post-surgery).
              <br />
              Looking forward to hearing back from you."
            </TextQuote>
            <ContactSubTitle style={{ textAlign: "center" }}>
              Choose from any of these options for contact. You will get a
              faster response via Telegram.
            </ContactSubTitle>
            <AllSocialsContainer>
              <StyledLink href="https://t.me/MayaMistress">
                <SocialContainer>
                  <FaTelegram className="social-icon" />
                  Telegram
                </SocialContainer>
              </StyledLink>

              <StyledLink href="https://wa.me/+31653153962">
                <SocialContainer>
                  <FaTelegram className="social-icon" />
                  WhatsApp
                </SocialContainer>
              </StyledLink>

              <StyledLink href="https://mailto:mayamistress@protonmail.com">
                <SocialContainer>
                  <SiProtonmail className="social-icon" />
                  Email
                </SocialContainer>
              </StyledLink>

              <StyledLink href="https://fetlife.com/users/6290666">
                <SocialContainer>
                  <TiHeart className="social-icon" />
                  FetLife
                </SocialContainer>
              </StyledLink>

              <StyledLink href="https://tryst.link/bdsm/mayamistress">
                <SocialContainer>
                  <TiHeart className="social-icon" />
                  My page on Tryst
                </SocialContainer>
              </StyledLink>
            </AllSocialsContainer>
          </TextContainer>
        </ContentContainer>
      </ContactContainer>
    </>
  );
};

const ContactContainer = styled.div`
  padding: 8rem 8.2rem;

  @media screen and (max-width: 500px) {
    margin: 4rem 0rem;
    padding: 0rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  padding-top: 5vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding: 0 0.5rem;
  }
`;

export const TextContainer = styled.div`
  margin: 0px 100px;

  @media screen and (max-width: 700px) {
    margin: 0rem 0.2rem;
  }
`;

export const ContactSubTitle = styled.p`
  font-size: 1rem;
  font-weight: 200;
  line-height: 2;
  font-family: "Kumbh Sans", sans-serif;
  @media screen and (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const SocialContainer = styled.div`
  font-size: 16px;
  padding: 1rem 0;
  color: white;
  font-family: "Kumbh Sans", sans-serif;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem 1.5rem;
  width: 250px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;

  @media screen and (max-width: 700px) {
    padding: 1rem 0;
    font-size: 1rem;
    width: 300px;
  }
`;

export const AllSocialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem 3rem 0rem;

  .social-icon {
    margin-right: 1rem;
    flex-direction: column;
  }

  @media screen and (max-width: 700px) {
    height: auto;
    font-size: 1rem;

    .social-icon {
      margin-right: 0.5rem;
    }
  }
`;

export const CopyRightsFooter = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: small;
  background-color: black;
`;

export const TextQuote = styled.div`
  font-size: 18px;
  font-style: italic;
  padding: 0rem 2rem;
`;

export const StyledLink = styled.a`
  text-decoration-color: white;
  text-decoration: none;
`;
