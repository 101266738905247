import styled from "styled-components";
import { CopyRightsFooter } from "../sections/ContactSection";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <a
          href="http://www.dickievirgin.com/"
          target="_blank"
          width={"100%"}
          rel="noreferrer"
        >
          <img
            src="http://www.dickievirgin.com/banner/dickie-main-standard.gif"
            alt="dickie virgin FemDom guide"
            width="100%"
          />
        </a>

        <a
          href="https://www.mistress-guide.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.mistress-guide.com/images/ourpics/mistress_guide_240x90_1.gif"
            alt="verified Mistress Guide"
            title="verified Mistress Guide"
            border="0"
          />
        </a>

        <a href="https://www.domina.guide/" target="_blank" rel="noreferrer">
          <img
            src="https://www.domina.guide/domina-guide.jpg"
            alt="Dominatrixes and BDSM Directory"
            width={"100%"}
          />
        </a>
      </FooterContent>
      <CopyRightsFooter>
        <br />
        Copyright © {new Date().getFullYear()} | All rights reserved
        <br />
        This website is under construction. Thank you for your patience.
      </CopyRightsFooter>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  background-color: black;
  color: white;
  width: 100%;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  flex-wrap: wrap;

  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 600px) {
    max-width: 300px;
    padding: 0rem;
  }
`;
