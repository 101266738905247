import React from "react";
import styled from "styled-components";
import background from "../assets/background.jpeg";
import backgroundVertical from "../assets/gallery/darkMM1.png";

export const HomeSection = () => {
  return (
    <>
      <div href="home" style={{ color: "transparent" }}>
        Home
      </div>
      <HomeContainer>
        <FixedContainer>
          <BackgroundContainer>
            <Background />
          </BackgroundContainer>
          <RelativeContainer>
            <TitleContainer>
              <Title>Maya Mistress</Title>
            </TitleContainer>
            <SubTitle>
              Professional Dominatrix based in Amsterdam. Power Dynamics
              Specialist.
            </SubTitle>
          </RelativeContainer>
        </FixedContainer>
      </HomeContainer>
    </>
  );
};

const FixedContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
`;

const HomeContainer = styled.div`
  height: 92vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -2;
`;

const Background = styled.div`
  background-image: url(${background});
  background-position: center;
  height: 130vh;
  width: 130vw;
  margin-left: -18vw;
  margin-top: -10vh;

  z-index: -2;
  opacity: 27%;
  background-size: cover;

  @media screen and (max-width: 468px) {
    background-image: url(${backgroundVertical});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    height: 90vh;
    margin-top: 5vh;
    opacity: 40%;
  }
`;

const RelativeContainer = styled.div`
  animation: fadeIn 2s ease-in-out;
  margin-top: -200px;
  margin-left: 10vw;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 468px) {
    margin: 0 10vw;
  }
`;

const TitleContainer = styled.div``;

const Title = styled.h1`
  color: white;
  font-size: 72px;
  font-weight: 200;
  letter-spacing: 4px;
  margin-bottom: 14px;
  margin-top: 0px;
  font-family: "Scriptina";

  @media screen and (max-width: 468px) {
    font-size: 48px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const SubTitle = styled.h2`
  font-size: 20px;
  color: white;
  font-weight: 300;
  animation: fadeIn 1s ease-in-out;

  @media screen and (max-width: 468px) {
    font-size: 18px;
  }
`;
