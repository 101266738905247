import React from "react";

import "./App.css";
import "./styles";
import "./menu.css";
import "yet-another-react-lightbox/styles.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import { InPersonSession } from "./pages/InPersonSession";
import { Navbar } from "./components/Navbar";
import { MentoringPage } from "./pages/Mentoring";
import { RemotePage } from "./pages/Remote";
import { Footer } from "./components/Footer";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
      <Helmet>
        <title>Maya Mistress | Independent Dominatrix in Amsterdam </title>
        <meta
          name="Maya Mistress | Independent Dominatrix in Amsterdam"
          content="Independent Pro Domme based in Amsterdam"
        />
        <link rel="canonical" href="https://mayamistress.nl" />
      </Helmet>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/sessions">
            <InPersonSession />
          </Route>

          <Route path="/mentoring">
            <MentoringPage />
          </Route>

          <Route path="/remote">
            <RemotePage />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
