import React from "react";
import styled from "styled-components";
import { SectionContainer } from "../components/SectionContainer";
import { SectionTitle } from "../components/SectionTitle";
import AboutImage from "../assets/gallery/about.jpg";

export const AboutSection = () => {
  return (
    <>
      <div id="about" style={{ color: "transparent" }}>
        About
      </div>
      <SectionContainer background={"black"} center>
        <AboutContent>
          <SectionTitle title="About" mobileOnly />
          <ImageContainer />
          <AboutText>
            <SectionTitle title="About" hideOnMobile />
            <QuoteStyleTextWrapper>
              <QuoteStyleText>Sensual, yet sadistic. </QuoteStyleText>
              <QuoteStyleText>Playful, yet merciless. </QuoteStyleText>
              <QuoteStyleText> Spicy, yet sweet.</QuoteStyleText>
            </QuoteStyleTextWrapper>
            Female Domination can be experienced as a fetish... Or as something
            more. I am eternally intrigued by what shapes society. Its unwritten
            laws, its hidden codes, the back end code of the unconscious ways we
            as humans interact with each other. In other words, by power
            dynamics. In between societies, within a society, between each
            person in each moment, in our day to day lives. And this journey
            eventually led me into the realm of BDSM and Female Domination. I
            believe that by establishing certain dynamics in society, every one
            of us have disconnected and forgotten about vital, essential, true
            and raw parts of ourselves. And by questioning such dynamics, in the
            right context and techniques, we are able to remember. And by
            remembering, we have the opportunity to embrace, to heal, and when
            needed… to rebel.
          </AboutText>
        </AboutContent>
      </SectionContainer>
    </>
  );
};

const AboutContent = styled.article`
  line-height: 2;
  margin-bottom: 4rem;
  display: flex;
  gap: 28px;
  max-width: 1200px;
  font-size: 18px;
  line-height: 2;

  @media screen and (max-width: 468px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 35%;
  height: 70vh;
  background-image: url(${AboutImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 468px) {
    width: 100%;
  }
`;

const AboutText = styled.div`
  width: 65%;

  @media screen and (max-width: 468px) {
    width: 100%;
    font-size: 18px;
  }
`;

const QuoteStyleTextWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const QuoteStyleText = styled.span`
  font-style: italic;
  text-align: center;
  font-size: 24px;
  margin-right: 5px;
`;
